// Supermove
import {gql} from '@supermove/graphql';
import {useQuery, useEffect} from '@supermove/hooks';

// App
import {SSEEventType} from '@shared/modules/SSE/interfaces';
import {useSSEv2} from '@shared/modules/SSE/v2/hooks/useSSEv2';

const useUserNotificationsCount = () => {
  const {loading, data, refetch, startPolling, stopPolling} = useQuery(
    useUserNotificationsCount.query,
    {
      fetchPolicy: 'cache-and-network',
    },
  );
  const {latestEvent, connected} = useSSEv2([SSEEventType.NOTIFICATION_COUNT_UPDATED], []);

  useEffect(() => {
    if (latestEvent && !loading && data?.viewer?.unseenNotificationsCount === 0) {
      // We only need to refetch the count if there are currently no notifs for a user,
      // because we don't show the count in the UI anymore.
      refetch();
    }
  }, [latestEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // polling is a fallback for SSE
    if (!loading && !connected && data?.viewer?.unseenNotificationsCount === 0) {
      // Start polling for notifications if a user doesn't have any
      startPolling(1000 * 30);
    } else {
      // If a user already has notifications, we'll stop polling to reduce DB load.
      // we don't necessarily care if this number is 100% accurate.
      stopPolling();
    }

    return () => {
      stopPolling(); // Stop polling when the component is unmounted
    };
  }, [connected, data, startPolling, stopPolling, loading]);

  return {
    unseenNotificationsCount: data?.viewer?.unseenNotificationsCount || 0,
    refetch,
  };
};

useUserNotificationsCount.query = gql`
  query useUserNotificationsCount {
    ${gql.query}
    viewer {
      id
      unseenNotificationsCount
    }
  }
`;

export default useUserNotificationsCount;
