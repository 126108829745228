// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useHandleMutationAlertErrors, useToast} from '@supermove/hooks';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import useDeleteWorkflowMutation from '@shared/modules/Workflow/hooks/useDeleteWorkflowMutation';

const DeleteWorkflowModal = ({workflow, isOpen, handleClose, onSuccess}: any) => {
  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});
  const deleteWorkflowToast = useToast({
    ToastComponent: SuccessToast,
    message: `"${workflow.name}" deleted`,
  });
  const workflowForm = WorkflowForm.delete({workflowId: workflow.id});
  const {handleSubmit, submitting} = useDeleteWorkflowMutation({
    workflowForm,
    onSuccess: () => {
      handleClose();
      deleteWorkflowToast.handleToast();
      onSuccess && onSuccess();
    },
    onError: (errors: any) => {
      handleClose();
      console.log({errors});
      alertHandler.handleMutationAlertErrors({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${workflow.name}?`}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteWorkflowModal.fragment = gql`
  fragment DeleteWorkflowModal on Workflow {
    id
    name
  }
`;

export default DeleteWorkflowModal;
