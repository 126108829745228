// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ReorderingDragAndDrop, Form, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDragAndDrop, usePopover} from '@supermove/hooks';
import {UserFlowModel, UserFlowStepModel} from '@supermove/models';
import {List} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import {UserFlowFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowForm';
import UserFlowRunStepForm, {
  UserFlowRunStepFormType,
} from '@shared/modules/UserFlows/forms/UserFlowRunStepForm';
import {UserFlowStepFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowStepForm';
import CreateDocumentFlowRunStepsListItem from 'modules/Project/DocumentFlow/components/CreateDocumentFlowRunStepsListItem';

const handleReorder = ({
  form,
  field,
  fromIndex,
  toIndex,
}: {
  form: Form<{userFlowForm: UserFlowFormToFormType}>;
  field: string;
  fromIndex: number;
  toIndex: number;
}) => {
  const userFlowStepForms = _.get<UserFlowStepFormToFormType[]>(form.values, field, []);
  const reorderedUserFlowStepForms = List.move({
    list: userFlowStepForms,
    fromIndex,
    toIndex,
  });
  form.setFieldValue(field, reorderedUserFlowStepForms);
};

const toUserFlowSteps = (
  userFlowRunStepForms: UserFlowRunStepFormType[],
  userFlow: UserFlowModel,
): UserFlowStepModel[] => {
  return userFlowRunStepForms.reduce<UserFlowStepModel[]>((flowSteps, runStep) => {
    const matchingFlowStep = userFlow.userFlowSteps.find(
      (flowStep) => flowStep.id === runStep.userFlowStepId,
    );

    if (matchingFlowStep) {
      flowSteps.push(matchingFlowStep);
    }

    return flowSteps;
  }, []);
};

const getActionsForDocumentFlows = (
  userFlowRunStepForms: UserFlowRunStepFormType[],
  userFlow: UserFlowModel,
  onDocumentFlowStepSelect: (userFlowStep: UserFlowStepModel) => void,
) => {
  const unusedFlowSteps = userFlow.userFlowSteps.filter((flowStep) => {
    const isStepUsed = userFlowRunStepForms.some(
      (runStep) => _.toString(runStep.userFlowStepId) === flowStep.id,
    );
    return !isStepUsed;
  });

  return unusedFlowSteps.map((flowStep) => ({
    text: flowStep.documentTemplate.name,
    onPress: () => onDocumentFlowStepSelect(flowStep),
  }));
};

const CreateDocumentFlowRunSteps = ({
  userFlow,
  form,
  field,
}: {
  userFlow: UserFlowModel;
  form: Form<{userFlowForm: UserFlowFormToFormType}>;
  field: string;
}) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();
  const selectStepPopover = usePopover();

  const userFlowRunStepForms = _.get<UserFlowRunStepFormType[]>(form.values, field, []);
  const userFlowSteps = toUserFlowSteps(userFlowRunStepForms, userFlow);

  const handleAddStep = (userFlowStep: UserFlowStepModel) => {
    // Marking formik as touched when step is added to reset errors
    form.setTouched({...form.touched, [field]: true});
    form.setFieldValue(field, [
      ...userFlowRunStepForms,
      UserFlowRunStepForm.toForm(UserFlowRunStepForm.new(userFlowStep)),
    ]);
  };

  const handleDelete = (index: number) => {
    form.setFieldValue(
      field,
      userFlowRunStepForms.filter((_, i) => i !== index),
    );
  };

  const actionsForDocumentFlows = getActionsForDocumentFlows(
    userFlowRunStepForms,
    userFlow,
    (userFlowStep) => handleAddStep(userFlowStep),
  );

  const stepsErrorMessage = _.get(form.errors, field);

  return (
    <React.Fragment>
      <ReorderingDragAndDrop
        handleReorder={({fromIndex, toIndex}) =>
          handleReorder({
            form,
            field,
            fromIndex,
            toIndex,
          })
        }
        spaceBetweenItems={16}
        items={userFlowSteps}
        itemIdExtractor={'id'}
        renderItem={(userFlowStep, index) => (
          <CreateDocumentFlowRunStepsListItem
            index={index}
            userFlowStep={userFlowStep}
            handleDelete={() => handleDelete(index)}
          />
        )}
      />
      <Space height={8} />
      <ActionMenuPopover
        popover={selectStepPopover}
        actions={actionsForDocumentFlows}
        width={400}
        popoverMaxHeight={556}
      >
        <TertiaryButton
          text={'Add Step'}
          iconLeft={Icon.Plus}
          onPress={selectStepPopover.handleOpen}
        />
      </ActionMenuPopover>
      {stepsErrorMessage ? (
        <FieldInput.Footer warningMessage={stepsErrorMessage} hasWarning />
      ) : null}
    </React.Fragment>
  );
};

CreateDocumentFlowRunSteps.fragment = gql`
  ${CreateDocumentFlowRunStepsListItem.fragment}
  fragment CreateDocumentFlowRunStepsFragment on UserFlow {
    id
    userFlowSteps {
      id
      ...CreateDocumentFlowRunStepsListItem
    }
  }
`;

export default CreateDocumentFlowRunSteps;
