// Libraries
import React from 'react';

// App
import {gql} from '@supermove/graphql';
import {Project} from '@supermove/models';

// Components
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import CreateProjectConfirmationSuccessModal from 'modules/Project/Confirmation/components/CreateProjectConfirmationSuccessModal';
import ProjectPageWithNavigation from 'modules/Project/components/ProjectPageWithNavigation';
import ProjectQuoteAndConfirmationPageContent from 'modules/Project/components/ProjectQuoteAndConfirmationPageContent';

const ConfirmationProjectPage = ({
  match: {
    params: {uuid},
  },
}: any) => {
  return (
    <ProjectPageWithNavigation
      fetchPolicy={'network-only'}
      query={ConfirmationProjectPage.query}
      variables={{uuid}}
      title={({loading, data}: any) => (loading ? 'Loading...' : Project.getName(data.project))}
      onClose={({navigator}: any) => navigator.goBack()}
    >
      {({data}: any) => (
        <CreateProjectConfirmationSuccessModal
          project={data.project}
          trigger={({handleOpen}: any) => (
            <ProjectQuoteAndConfirmationPageContent
              viewer={data.viewer}
              project={data.project}
              onSuccess={handleOpen}
              kind={ConfirmationStepsKind.CONFIRMATION}
              pageTitle={'Prepare & Send Confirmation'}
              pageSubtitle={
                `Please configure the confirmation steps for ` +
                `${Project.getName(data.project)} and draft an email ` +
                `to send to ${data.project.customer.fullName}.`
              }
              emailTemplate={data.project.projectType.confirmationConfirmationSteps.emailTemplate}
            />
          )}
        />
      )}
    </ProjectPageWithNavigation>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationProjectPage.query = gql`
  ${ProjectPageWithNavigation.fragment}
  ${CreateProjectConfirmationSuccessModal.fragment}
  ${Project.getName.fragment}
  ${ProjectQuoteAndConfirmationPageContent.fragment}

  query ConfirmationProjectPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
      ...ProjectQuoteAndConfirmationPageContent_User
    }
    project(uuid: $uuid) {
      id
      uuid
      customer {
        id
        fullName
      }
      projectType {
        id
        confirmationConfirmationSteps: confirmationStepsByKind(kind: "CONFIRMATION"){
          id
          emailTemplate {
            id
            ...ProjectQuoteAndConfirmationPageContent_EmailTemplate
          }
        }
      }
      ...CreateProjectConfirmationSuccessModal
      ...ProjectPageWithNavigation
      ...Project_getName
      ...ProjectQuoteAndConfirmationPageContent_Project
    }
  }
`;

export default ConfirmationProjectPage;
