// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useToast,
  useHandleMutationAlertErrors,
  useDrawer,
  useModal,
  useNavigationDOM,
  useResponsive,
} from '@supermove/hooks';
import {WorkflowModel} from '@supermove/models';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {Datetime} from '@supermove/utils';

// App
import SwitchField from '@shared/design/components/Field/SwitchField';
import Table from '@shared/design/components/Table';
import {ColumnDefinitionType} from '@shared/design/components/Table/components/TableBuilder';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ToggleWorkflowIsActiveForm from '@shared/modules/Workflow/ToggleWorkflowIsActiveForm';
import useToggleWorkflowIsActiveMutation from '@shared/modules/Workflow/hooks/useToggleWorkflowIsActiveMutation';
import DeleteWorkflowModal from 'modules/Organization/Settings/Workflow/components/DeleteWorkflowModal';
import DuplicateWorkflowModal from 'modules/Organization/Settings/Workflow/components/DuplicateWorkflowModal';
import UpdateWorkflowDrawer from 'modules/Organization/Settings/Workflow/components/UpdateWorkflowDrawer';

const IconButton = Styled.ButtonV2`
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const ToggleSwitch = ({workflow}: any) => {
  const toggleWorkflowIsActiveSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Automation Status Updated',
  });

  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});

  const toggleWorkflowIsActiveForm = ToggleWorkflowIsActiveForm.new({
    workflowId: workflow.id,
    isActive: workflow.isActive,
  });

  const {form, handleSubmit} = useToggleWorkflowIsActiveMutation({
    toggleWorkflowIsActiveForm,
    onSuccess: () => {
      toggleWorkflowIsActiveSuccessToast.handleToast();
    },
    onError: (errors: any) => {
      form.setFieldValue(
        'toggleWorkflowIsActiveForm.isActive',
        toggleWorkflowIsActiveForm.isActive,
      );
      console.log({errors});
      alertHandler.handleMutationAlertErrors({errors});
    },
  });
  return (
    <SwitchField
      form={form}
      field={'toggleWorkflowIsActiveForm.isActive'}
      // @ts-expect-error TS(2322): Type '(options?: MutationFunctionOptions<MutationR... Remove this comment to see the full error message
      onChangeValue={handleSubmit}
    />
  );
};

const getWorkflowsTableColumnDefinitions = ({
  refetch,
  navigator,
}: {
  refetch: () => void;
  navigator: SupermoveNavigatorType;
}): ColumnDefinitionType<WorkflowModel>[] => [
  {
    width: 48,
    cellComponent: (workflow) => {
      return <ToggleSwitch workflow={workflow} key={workflow.id} />;
    },
    mobileOptions: {
      isInHeader: true,
    },
  },
  {
    flex: 2,
    headerLabel: 'Name',
    cellText: (workflow) => workflow.name,
    mobileOptions: {
      isInHeader: true,
    },
  },
  {
    flex: 3,
    headerLabel: 'Description',
    tooltip: (workflow) => workflow.description,
    cellText: (workflow) => workflow.description || '-',
    mobileOptions: {
      isInHeader: true,
    },
  },
  {
    flex: 2,
    headerLabel: 'Last Updated',
    cellText: (workflow) => Datetime.convertToDisplayDatetime(workflow.updatedAt),
    secondary: {
      cellText: (workflow) => workflow.updatedBy.fullName,
    },
    mobileOptions: {
      isInHeader: true,
    },
  },
  {
    flex: 1,
    headerLabel: 'Actions',
    actions: (workflow) => {
      return [
        {
          text: 'Edit',
          desktopIcon: Icon.Pen,
          onPress: ({handleOpen}: {handleOpen?: () => void}) => handleOpen?.(),
          actionHook: {
            hook: useDrawer,
            hookArgument: {name: 'Update Workflow Drawer'},
            renderComponent: ({
              hookKey,
              isOpen,
              handleClose,
            }: {
              hookKey: string;
              isOpen: boolean;
              handleClose: () => void;
            }) => {
              return (
                <UpdateWorkflowDrawer
                  key={hookKey}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  workflow={workflow}
                  onSuccess={() => {
                    handleClose();
                    refetch();
                  }}
                />
              );
            },
          },
        },
        {
          text: 'View Automation Runs',
          onPress: () => navigator.push(`/settings/automations/${workflow.uuid}/runs`),
        },
        {
          text: 'Delete Automation',
          onPress: ({handleOpen}: {handleOpen?: () => void}) => handleOpen?.(),
          actionHook: {
            hook: useModal,
            hookArgument: {name: 'Delete Automation Modal'},
            renderComponent: ({
              hookKey,
              isOpen,
              handleClose,
            }: {
              hookKey: string;
              isOpen: boolean;
              handleClose: () => void;
            }) => {
              return (
                <DeleteWorkflowModal
                  key={`Delete_${hookKey}`}
                  isOpen={isOpen}
                  workflow={workflow}
                  handleClose={handleClose}
                  onSuccess={() => {
                    handleClose();
                    refetch();
                  }}
                />
              );
            },
          },
        },
        {
          text: 'Duplicate Automation',
          onPress: ({handleOpen}: {handleOpen?: () => void}) => handleOpen?.(),
          actionHook: {
            hook: useModal,
            hookArgument: {name: 'Duplicate Automation Modal'},
            renderComponent: ({
              hookKey,
              isOpen,
              handleClose,
            }: {
              hookKey: string;
              isOpen: boolean;
              handleClose: () => void;
            }) => {
              return (
                <DuplicateWorkflowModal
                  key={`Duplicate_${hookKey}`}
                  isOpen={isOpen}
                  workflow={workflow}
                  handleClose={handleClose}
                  onSuccess={() => {
                    handleClose();
                    refetch();
                  }}
                />
              );
            },
          },
        },
      ];
    },
  },
];

const WorkflowsTable = ({
  workflows,
  refetch,
  onRowPress,
}: {
  workflows: WorkflowModel[];
  refetch: () => void;
  onRowPress?: () => void;
}) => {
  const responsive = useResponsive();
  const {
    navigator,
    params: {workflowUuid},
  } = useNavigationDOM();
  return (
    <React.Fragment>
      <Table
        columnDefinitions={getWorkflowsTableColumnDefinitions({
          refetch,
          navigator,
        })}
        emptyStateText='No automations'
        items={workflows}
        onRowPress={(workflow) => {
          navigator.replace(`/settings/automations/${workflow.uuid}`);
          onRowPress?.();
        }}
        activeRowIndex={_.findIndex(workflows, (workflow) => workflow.uuid === workflowUuid)}
        isScrollable={responsive.desktop}
      />
      <Space height={32} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowsTable.fragment = gql`
  ${UpdateWorkflowDrawer.fragment}
  ${DeleteWorkflowModal.fragment}
  ${DuplicateWorkflowModal.fragment}

  fragment WorkflowsTable on Workflow {
    id
    name
    description
    uuid
    isActive
    kind
    updatedAt
    updatedBy {
      id
      fullName
    }
    owner {
      id
      fullName
    }
    ...UpdateWorkflowDrawer
    ...DeleteWorkflowModal
    ...DuplicateWorkflowModal
  }
`;

export default WorkflowsTable;
