// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import IconButton from '@shared/design/components/IconButton';
import ModalComponent from '@shared/design/components/Modal';
import {NavigationItem} from 'core/NavigationItems';
import DashboardsPageDashboardsList from 'modules/Report/components/DashboardsPageDashboardsList';

type DashboardsPageDashboardsListModalProps = {
  navigationItems: NavigationItem[];
  selectedDashboardUuid: string | null;
  isOpen: boolean;
  handleClose: () => void;
  handleSelect: (dashboardUuid: string) => void;
};

const DashboardsPageDashboardsListModal = ({
  navigationItems,
  selectedDashboardUuid,
  isOpen,
  handleSelect,
  handleClose,
}: DashboardsPageDashboardsListModalProps) => {
  const handleItemSelect = (dashboardUuid: string | null) => {
    handleSelect(dashboardUuid ?? '');
    handleClose();
  };

  return (
    <ModalComponent.Responsive
      isOpen={isOpen}
      width={ModalComponent.WIDTH.LARGE}
      handlePressOutside={handleClose}
    >
      <ModalComponent.Header style={{justifyContent: 'space-between', borderBottomWidth: 0}}>
        <Space width={12} />
        <ModalComponent.HeaderText>{'Go to Report'}</ModalComponent.HeaderText>
        <IconButton source={Icon.Xmark} isSecondary onPress={handleClose} />
      </ModalComponent.Header>
      <ModalComponent.Body
        style={{
          backgroundColor: colors.white,
          padding: '8px 16px',
          flex: 1,
        }}
      >
        <DashboardsPageDashboardsList
          navigationItems={navigationItems}
          selectedDashboardUuid={selectedDashboardUuid}
          handleSelect={handleItemSelect}
        />
      </ModalComponent.Body>
    </ModalComponent.Responsive>
  );
};

export default DashboardsPageDashboardsListModal;
