// Libraries
import React from 'react';

// Supermove
import {useToast} from '@supermove/hooks';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useDeleteUserMutation from '@shared/modules/User/hooks/useDeleteUserMutation';

const DeleteUserModal = ({
  isOpen,
  handleClose,
  refetch,
  userId,
  userFullName,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  userId: string;
  userFullName?: string;
}) => {
  // TODO(Hammad) userFullName is an optional prop for legacy support. Should be able to refactor during Invite Movers project and remove default fallback values.
  const defaultTitle = 'Are you sure you want to delete this user?';

  const deleteUserSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `${userFullName || 'User'} has been deleted.`,
  });

  const {handleSubmit} = useDeleteUserMutation({
    userId,
    onSuccess: () => {
      handleClose();
      refetch();
      deleteUserSuccessToast.handleToast();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={userFullName ? `Delete ${userFullName}?` : defaultTitle}
      subtitle={
        "This user will be removed from all existing records on any completed or scheduled jobs. If you want to keep all records intact, mark the user inactive. You can't undo this action."
      }
      handleClose={handleClose}
      handleDelete={handleSubmit}
    />
  );
};

export default DeleteUserModal;
