import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled, Popover, Icon, Link} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useModal, usePopover, usePagination, useState} from '@supermove/hooks';
import {useNavigationDOM} from '@supermove/navigation';
import {colors, Typography} from '@supermove/styles';
import {Datetime, titleize, URL} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Modal from '@shared/design/components/Modal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/TableV2Deprecated';
import useSendBulkInvoicesToCodatMutation from '@shared/modules/Billing/hooks/useSendBulkInvoicesToCodatMutation';
import FilteredCodatPushOperationsForm from '@shared/modules/Integration/forms/FilteredCodatPushOperationsForm';
import useDequeueInvoiceMutation from '@shared/modules/Integration/hooks/useDequeueInvoiceMutation';
import BatchStatus from '@shared/modules/Organization/enums/BatchStatus';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const LinkStyle = {
  fontFamily: 'Avenir, sans-serif',
  color: colors.blue.interactive,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
  textDecorationLine: 'underline',
  textDecorationColor: colors.blue.interactive,
  cursor: 'pointer',
  width: '100%',
};

const ActionsCell = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const TableContainer = Styled.View`
`;

const Container = Styled.View``;

const LinkWrapper = Styled.View`
  flex: 1;
`;

const Title = Styled.Text`
  ${Typography.PageHeading}
`;

const Row = Styled.View`
  justify-content: space-between;
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'isMobile' does not exist on type 'ThemeP... Remove this comment to see the full error message
    isMobile,
  }) => (isMobile ? 'column' : 'row')};
`;

const Column = Styled.View``;

const Description = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
`;

const getItem = (batchOperation: any) => {
  if (batchOperation.invoiceExport) {
    return `Invoice "${batchOperation.invoiceExport.invoice.identifier}"`;
  }
  if (batchOperation.paymentExport) {
    return `Payment "${batchOperation.paymentExport.payment.name}" (for Invoice ${batchOperation.paymentExport.payment.invoice.identifier})`;
  }
  if (batchOperation.clientExport) {
    return `Client "${batchOperation.clientExport.client.name}"`;
  }
  return 'Unknown';
};

const getItemLink = (batchOperation: any) => {
  if (batchOperation.invoiceExport) {
    return `/invoices/${batchOperation.invoiceExport.invoice.uuid}/view`;
  }
  if (batchOperation.paymentExport) {
    return `/invoices/${batchOperation.paymentExport.payment.invoice.uuid}/view`;
  }
  if (batchOperation.clientExport) {
    return `/clients/${batchOperation.clientExport.client.uuid}`;
  }
  return '';
};

const getLogsLink = (batchOperation: any, uuid: any) => {
  let queryParams = FilteredCodatPushOperationsForm.toQueryParams(
    FilteredCodatPushOperationsForm.new(),
  );
  if (batchOperation.invoiceExport) {
    queryParams = FilteredCodatPushOperationsForm.toQueryParams({
      ...FilteredCodatPushOperationsForm.new(),
      types: ['invoices'],
      searchQuery: batchOperation.invoiceExport.invoice.identifier,
    });
  }
  if (batchOperation.paymentExport) {
    queryParams = FilteredCodatPushOperationsForm.toQueryParams({
      ...FilteredCodatPushOperationsForm.new(),
      types: ['payments'],
      searchQuery: batchOperation.paymentExport.payment.invoice.identifier,
    });
  }
  if (batchOperation.clientExport) {
    queryParams = FilteredCodatPushOperationsForm.toQueryParams({
      ...FilteredCodatPushOperationsForm.new(),
      types: ['customers'],
      searchQuery: batchOperation.clientExport.client.name,
    });
  }
  return URL.getUrlFromVariables(`/settings/company/accounting/${uuid}/logs`, queryParams);
};

const BatchInvoiceTable = ({data, navigator, refetch, batchStatus, uuid}: any) => {
  const columnDefinitions = [
    {
      flex: 1,
      headerContent: () => <Table.HeaderText>Enqueued At</Table.HeaderText>,
      cellContent: ({item, index}: any) => (
        <Table.CellText>{Datetime.convertToDisplayDatetime(item.enqueuedAt)}</Table.CellText>
      ),
    },
    {
      flex: 1,
      headerContent: () => <Table.HeaderText>Item</Table.HeaderText>,
      cellContent: ({item, index}: any) => {
        const itemLink = getItemLink(item);
        return itemLink ? (
          <LinkWrapper>
            <Link style={LinkStyle} to={getItemLink(item)}>
              {getItem(item)}
            </Link>
          </LinkWrapper>
        ) : (
          <Table.CellText>(Deleted)</Table.CellText>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => <Table.HeaderText>Status</Table.HeaderText>,
      cellContent: ({item, index}: any) => (
        <Table.CellText>{titleize(item.status.replace('_', ' '))}</Table.CellText>
      ),
    },
    {
      flex: 3,
      headerContent: () => <Table.HeaderText>Errors</Table.HeaderText>,
      cellContent: ({item, index}: any) => {
        const isEmpty = item.validation === '{}';
        return !isEmpty ? (
          <Table.CellText>{item.latestCodatPushOperation?.prettyErrorMessage}</Table.CellText>
        ) : (
          <Table.CellText>-</Table.CellText>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => <Table.HeaderText>Actions</Table.HeaderText>,
      cellContent: ({item, index}: any) =>
        BatchStatus.isResyncable(item.status) ? (
          <BatchActions
            batch={item}
            refetch={refetch}
            codatBatchOperation={item}
            navigator={navigator}
            uuid={uuid}
          />
        ) : null,
    },
  ];

  return (
    <Table
      columnDefinitions={columnDefinitions}
      items={data}
      itemKey='id'
      emptyStateText='No batch exports to display.'
      isDense
    />
  );
};

const BatchActionsMenu = ({
  popover,
  dequeueInvoiceModal,
  requeueueInvoicesModal,
  batch,
  codatBatchOperation,
  uuid,
}: any) => {
  const menuItems = [];
  const invoice = codatBatchOperation?.invoiceExport?.invoice;
  if (invoice && batch.status === BatchStatus.PENDING) {
    menuItems.push(
      <ActionMenuPopover.MenuItem
        onPress={() => {
          dequeueInvoiceModal.handleOpen();
          popover.handleClose();
        }}
      >
        Remove From Batch
      </ActionMenuPopover.MenuItem>,
    );
  }
  if (BatchStatus.isResyncable(batch.status)) {
    menuItems.push(
      <ActionMenuPopover.MenuItem>
        <Link to={getLogsLink(codatBatchOperation, uuid)} style={LinkStyle}>
          View In Logs
        </Link>
      </ActionMenuPopover.MenuItem>,
    );
    if (invoice) {
      menuItems.push(
        <ActionMenuPopover.MenuItem
          onPress={() => {
            requeueueInvoicesModal.handleOpen();
            popover.handleClose();
          }}
        >
          Requeue In Next Batch
        </ActionMenuPopover.MenuItem>,
      );
    }
  }

  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.BottomStart} width={208}>
      {menuItems}
    </ActionMenuPopover>
  );
};

const DequeueInvoiceModal = ({dequeueInvoiceModal, invoiceId, refetch}: any) => {
  const {handleSubmit, submitting} = useDequeueInvoiceMutation({
    dequeueInvoiceForm: {invoiceId},
    onSuccess: () => {
      dequeueInvoiceModal.handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={dequeueInvoiceModal.isOpen}
      title={'Are you sure you want to remove this invoice from the batch?'}
      subtitle={'The client and all payments associated with this invoice will also be removed.'}
      handleClose={dequeueInvoiceModal.handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={'Remove'}
      isSubmitting={submitting}
    />
  );
};

export const RequeueInvoicesModal = ({requeueInvoicesModal, invoices, refetch, navigator}: any) => {
  // @ts-expect-error TS(2339): Property 'errors' does not exist on type '{ form: ... Remove this comment to see the full error message
  const {handleSubmit, submitting, errors} = useSendBulkInvoicesToCodatMutation({
    invoiceIds: invoices.map((invoice: any) => invoice.id),
    onSuccess: () => {
      requeueInvoicesModal.handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <Modal
      isOpen={requeueInvoicesModal.isOpen}
      width={Modal.WIDTH.MEDIUM}
      style={{maxHeight: '100%'}}
    >
      <Modal.Header>
        <Modal.HeaderText>Requeue Invoices</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body>
        <Modal.Text>
          The following invoices will be queued for the next batch export, along with their clients
          and associated payments:
        </Modal.Text>
        <ScrollView style={{maxHeight: 200}}>
          {(invoices || []).map((invoice: any) => (
            <Modal.Text>
              <LinkWrapper>
                <Link
                  style={LinkStyle}
                  to={`/invoices/${invoice.uuid}/view`}
                >{`Invoice "${invoice.identifier}"`}</Link>
              </LinkWrapper>
            </Modal.Text>
          ))}
        </ScrollView>
      </Modal.Body>
      <Modal.Footer>
        <React.Fragment>
          {(errors || []).map((error: any) => (
            <Modal.Text style={{color: colors.red.warning}}>{error.message}</Modal.Text>
          ))}
        </React.Fragment>
        <Modal.Button onPress={requeueInvoicesModal.handleClose}>Cancel</Modal.Button>
        <Modal.Button
          onPress={handleSubmit}
          isDisabled={submitting}
          color={colors.blue.interactive}
        >
          Requeue Invoices
        </Modal.Button>
      </Modal.Footer>
    </Modal>
  );
};

const BatchActions = ({refetch, codatBatchOperation, batch, navigator, uuid}: any) => {
  const {invoiceExport} = codatBatchOperation;
  const {invoice} = invoiceExport || {};
  const dequeueInvoiceModal = useModal({name: 'dequeueInvoiceModal'});
  const requeueueInvoicesModal = useModal({name: 'requeueueInvoicesModal'});
  const moverActionsPopover = usePopover();

  return (
    <React.Fragment>
      <ActionsCell>
        <Popover.Content innerRef={moverActionsPopover.ref}>
          <MoreActionsButton onPress={moverActionsPopover.handleOpen}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </MoreActionsButton>
        </Popover.Content>
      </ActionsCell>
      <BatchActionsMenu
        popover={moverActionsPopover}
        dequeueInvoiceModal={dequeueInvoiceModal}
        requeueueInvoicesModal={requeueueInvoicesModal}
        batch={batch}
        codatBatchOperation={codatBatchOperation}
        uuid={uuid}
      />
      {invoice ? (
        <DequeueInvoiceModal
          dequeueInvoiceModal={dequeueInvoiceModal}
          handleClose={dequeueInvoiceModal.handleClose}
          invoiceId={invoice.id}
          refetch={refetch}
        />
      ) : null}
      {invoice ? (
        <RequeueInvoicesModal
          requeueInvoicesModal={requeueueInvoicesModal}
          invoices={[invoice]}
          refetch={refetch}
          navigator={navigator}
        />
      ) : null}
    </React.Fragment>
  );
};

const AccountingExportsBatchContent = ({batch, navigator, refetch, pagination, uuid}: any) => {
  const {codatBatchOperationsPaginatedList, status, failedInvoices} = batch;
  const requeueAllFailedInvoicesModal = useModal({name: 'requeueAllFailedInvoicesModal'});
  return (
    <TableContainer>
      <Row>
        <Column style={{maxWidth: 600}}>
          <Title>{`Batch ${batch.id}`}</Title>
          <Space height={12} />
          <Description>{`See which invoices, clients, and payments are part of this batch.`}</Description>
        </Column>
        <ButtonContainer>
          {failedInvoices?.length ? (
            <SecondaryButton
              text={`Requeue ${failedInvoices.length} Failed Invoices`}
              onPress={() => {
                requeueAllFailedInvoicesModal.handleOpen();
              }}
            />
          ) : null}
        </ButtonContainer>
        <RequeueInvoicesModal
          requeueInvoicesModal={requeueAllFailedInvoicesModal}
          invoices={failedInvoices}
          refetch={refetch}
          navigator={navigator}
        />
      </Row>
      <Space height={24} />
      <TableContainer>
        <BatchInvoiceTable
          data={codatBatchOperationsPaginatedList.codatBatchOperations}
          navigator={navigator}
          refetch={refetch}
          batchStatus={status}
          uuid={uuid}
        />
        <Space height={12} />

        <Container>
          <PaginationBar pagination={pagination} />
        </Container>
      </TableContainer>
    </TableContainer>
  );
};

const AccountingExportsBatchLoading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <Title>
            <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={128} />
          </Title>
          <Space height={12} />
          <Description>{`See which invoices, clients, and payments are part of this batch.`}</Description>
        </Column>
      </Row>
      <Space height={24} />
      <TableContainer>
        <BatchInvoiceTable data={[]} />
        <Space height={12} />
        <Container>
          {/* @ts-expect-error TS(2322): Type '{}' is not assignable to type 'PaginationType'. */}
          <PaginationBar pagination={{}} />
        </Container>
      </TableContainer>
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage = () => {
  const {navigator, params} = useNavigationDOM();
  const [currentPage, setCurrentPage] = useState(1);

  const {loading, data, refetch} = useQuery(
    OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage.query,
    {
      fetchPolicy: 'network-only',
      variables: {
        batchUuid: params.batchUuid,
        pagination: {
          page: currentPage,
          resultsPerPage: 20,
        },
      },
    },
  );

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'batch.codatBatchOperationsPaginatedList.paginationMetadata'),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });
  const breadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {name: 'Company Settings', onPress: () => navigator.push('/settings/business-info')},
    {name: 'Accounting', onPress: () => navigator.push('/settings/company/accounting')},
    {
      name: `Accounting Exports`,
      onPress: () => navigator.push(`/settings/company/accounting/${params.uuid}/exports`),
    },
  ];
  if (data?.batch?.id) {
    // @ts-expect-error TS(2345): Argument of type '{ name: string; }' is not assign... Remove this comment to see the full error message
    breadcrumbs.push({name: `Batch ${data.batch.id}`});
  }

  return (
    <SettingsPage breadcrumbs={breadcrumbs}>
      {loading ? (
        <AccountingExportsBatchLoading />
      ) : (
        <AccountingExportsBatchContent
          batch={data.batch}
          navigator={navigator}
          refetch={refetch}
          pagination={pagination}
          uuid={params.uuid}
        />
      )}
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage.query = gql`
  ${usePagination.fragment}
  query OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage(
    $batchUuid: String!
    $pagination: PaginationInput!
  ) {
    batch: batchByUuid(batchUuid: $batchUuid) {
      id
      status
      failedInvoices {
        id
        uuid
        identifier
      }
      codatBatchOperationsPaginatedList(pagination: $pagination) {
        codatBatchOperations: results {
          id
          enqueuedAt
          status
          latestCodatPushOperation {
            id
            prettyErrorMessage
          }
          invoiceExport {
            id
            invoice {
              id
              uuid
              identifier
            }
          }
          paymentExport {
            id
            payment {
              id
              name
              invoice {
                id
                uuid
                identifier
              }
            }
          }
          clientExport {
            id
            client {
              id
              uuid
              name
            }
          }
        }
        paginationMetadata {
          ...usePagination
        }
      }
    }
    viewer {
      id
    }
  }
`;

export default OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage;
