// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useHandleMutationAlertErrors} from '@supermove/hooks';

// App
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import TagForm from '@shared/modules/Tag/forms/TagForm';
import useUnarchiveTagMutation from '@shared/modules/Tag/hooks/useUnarchiveTagMutation';

const UnarchiveTagModal = ({tag, isOpen, handleClose, onSuccess}: any) => {
  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});
  const tagForm = TagForm.edit(tag);
  const {handleSubmit, submitting} = useUnarchiveTagMutation({
    tagForm,
    onSuccess: () => {
      handleClose();
      onSuccess && onSuccess();
    },
    onError: (errors: any) => {
      handleClose();
      console.log({errors});
      alertHandler.handleMutationAlertErrors({errors});
    },
  });

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={`Unarchive ${tag.name}?`}
      subtitle={'Do you want to unarchive this tag?'}
      handleSecondaryAction={handleClose}
      handlePrimaryAction={handleSubmit}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UnarchiveTagModal.fragment = gql`
  fragment UnarchiveTagModal on Tag {
    id
    ...TagForm_edit
  }
`;

export default UnarchiveTagModal;
