// Libararies
import React from 'react';

// Supermove
import {Loading, Space, ToastContainer} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useHandleMutationAlertErrors} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import TripForm from '@shared/modules/Dispatch/forms/TripForm';
import useCreateTripMutation from '@shared/modules/Dispatch/hooks/useCreateTripMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CreateTripSuccessModal from 'modules/Dispatch/LongDistance/components/CreateTripSuccessModal';
import TripModalContent from 'modules/Dispatch/LongDistance/components/TripModalContent';

const CreateTripWithShipmentsModalContent = ({
  organization,
  handleClose,
  shipments,
  onSuccess,
}: any) => {
  const createTripSuccessModal = useModal({name: 'Create Trip Success Modal'});
  const tripForm = TripForm.newWithShipments({organizationId: organization.id, shipments});
  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});
  const field = 'tripForm';
  const {form, handleSubmit, submitting} = useCreateTripMutation({
    tripForm,
    onSuccess: ({trip}: any) => {
      onSuccess();
      form.setFieldValue(`${field}.tripUuid`, trip.uuid);
      createTripSuccessModal.handleOpen();
    },
    onError: (errors: any) => {
      console.log({errors});
      alertHandler.handleMutationAlertErrors({errors});
    },
  });
  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{'Create Trip'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <TripModalContent form={form} field={field} organization={organization} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button onPress={handleClose} isDisabled={submitting}>
          {'Cancel'}
        </Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={handleSubmit}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Save'}
        </Modal.Button>
      </Modal.Footer>
      <CreateTripSuccessModal
        form={form}
        field={field}
        isOpen={createTripSuccessModal.isOpen}
        handleClose={handleClose}
      />
      <ToastContainer />
    </React.Fragment>
  );
};

const CreateTripWithShipmentsModal = ({isOpen, handleClose, shipments, onSuccess}: any) => {
  const {data, loading} = useQuery(CreateTripWithShipmentsModal.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.LARGE} style={{flex: 1}}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <CreateTripWithShipmentsModalContent
              organization={data.viewer.viewingOrganization}
              handleClose={handleClose}
              shipments={shipments}
              onSuccess={onSuccess}
            />
          );
        }}
      </Loading>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateTripWithShipmentsModal.query = gql`
  ${TripModalContent.fragment}
  query CreateTripWithShipmentsModal {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...TripModalContent_Organization
      }
    }
  }
`;

CreateTripWithShipmentsModal.fragment = gql`
  ${TripForm.newWithShipments.fragment}
  fragment CreateTripWithShipmentsModal on Shipment {
    id
    ...TripForm_newWithShipments
  }
`;

export default CreateTripWithShipmentsModal;
