// Supermove

// App
import {ChannelType, SSEEventType} from '@shared/modules/SSE/interfaces';
import {useSSEv2} from '@shared/modules/SSE/v2/hooks/useSSEv2';

export const useCommunicationNewSseSubscription = () => {
  const {latestEvent, setChannelFilters} = useSSEv2([SSEEventType.COMMUNICATION_NEW], []);

  const subscribeToProject = (projectId: string) => {
    const channelFilters = {
      channelId: projectId,
      channelType: ChannelType.PROJECT,
    };
    setChannelFilters([channelFilters]);
  };

  const subscribeToOrganization = (organizationId: string) => {
    const channelFilters = {
      channelId: organizationId,
      channelType: ChannelType.ORGANIZATION,
    };
    setChannelFilters([channelFilters]);
  };

  const subscribeToUser = (userId: string) => {
    const channelFilters = {
      channelId: userId,
      channelType: ChannelType.USER,
    };
    setChannelFilters([channelFilters]);
  };

  const unsubscribe = () => {
    setChannelFilters([]);
  };

  return {latestEvent, subscribeToProject, subscribeToOrganization, subscribeToUser, unsubscribe};
};
