// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Icon,
  Loading,
  Styled,
  Space,
  Sidebar,
  ScrollView,
  ToastContainer,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  usePagination,
  useQuery,
  useState,
  useHover,
  useDebouncedCallback,
  useHandleMutationAlertErrors,
} from '@supermove/hooks';
import {Location} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import SearchBar from '@shared/design/components/SearchBar';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import AssignShipmentsToTripForm from '@shared/modules/Dispatch/forms/AssignShipmentsToTripForm';
import useAssignShipmentsToTripMutation from '@shared/modules/Dispatch/hooks/useAssignShipmentsToTripMutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const SidebarContainer = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  width: 400px;
`;

const Container = Styled.View`
`;

const HeaderContainer = Styled.View`
  padding: 12px 16px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const TripBlockContainer = Styled.ButtonV2<{isHovered: boolean; isSelected: boolean}>`
  padding: 12px;
  background-color: ${({isHovered, isSelected}) => getTripBlockBackgroundColor({isHovered, isSelected})};
  cursor: pointer;
`;

const TripsContainer = Styled.View`
  padding: 12px;
`;

const TripName = Styled.Text`
  ${Typography.Body3}
`;

const TripBodyText = Styled.Text`
  ${Typography.Body4}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const WeightInfo = Styled.Text`
  ${Typography.Label4}
  color: ${colors.gray.primary};
  background-color: ${colors.blue.accent};
  padding: 4px 8px;
  border-radius: 4px;
`;

const getTripBlockBackgroundColor = ({isHovered, isSelected}: any) => {
  if (isSelected || isHovered) {
    return colors.hover;
  }
  return colors.white;
};

const handleTripPress = ({form, trip, shipmentIds}: any) => {
  if (form.values.assignShipmentsToTripForm.tripId === trip.id) {
    form.setFieldValue('assignShipmentsToTripForm.tripId', null);
  } else {
    form.setFieldValue('assignShipmentsToTripForm.tripId', trip.id);
    form.setFieldValue('assignShipmentsToTripForm.shipmentIds', shipmentIds);
  }
};

const TripBlock = ({trip, form, shipmentIds}: any) => {
  const {ref, isHovered} = useHover();
  return (
    <TripBlockContainer
      ref={ref}
      isHovered={isHovered}
      isSelected={form.values.assignShipmentsToTripForm.tripId === trip.id}
      onPress={() => {
        handleTripPress({form, trip, shipmentIds});
      }}
    >
      <Row style={{justifyContent: 'space-between'}}>
        <TripName>{trip.name}</TripName>
        <WeightInfo>{trip.totalWeight} lbs</WeightInfo>
      </Row>
      <Space height={4} />
      <Row>
        <Icon source={Icon.User} size={10} color={colors.gray.secondary} />
        <Space width={6} />
        <TripBodyText>{trip.driver ? trip.driver.fullName : 'TBD'}</TripBodyText>
        <Space width={9} />
        <Icon source={Icon.Truck} size={10} color={colors.gray.secondary} />
        <Space width={6} />
        <TripBodyText>Truck, Truck</TripBodyText>
      </Row>
      <Space height={4} />
      <Row>
        <Icon source={Icon.Calendar} size={10} color={colors.gray.secondary} />
        <Space width={6} />
        <TripBodyText>
          {trip.startDate ? Datetime.convertToDisplayDate(trip.startDate, 'M/D/YY') : 'TBD'} -{' '}
          {trip.endDate ? Datetime.convertToDisplayDate(trip.endDate, 'M/D/YY') : 'TBD'}
        </TripBodyText>
      </Row>
      <Space height={4} />
      <Row>
        <Space width={2} />
        <Icon source={Icon.MapPin} size={10} color={colors.gray.secondary} />
        <Space width={7} />
        <TripBodyText>
          {Location.getDisplayCityStateZip(trip.startLocation, 'TBD')} -{' '}
          {Location.getDisplayCityStateZip(trip.endLocation, 'TBD')}
        </TripBodyText>
      </Row>
    </TripBlockContainer>
  );
};

const PaginationFooter = ({paginationMetadata, page, setPage}: any) => {
  const pagination = usePagination({
    currentPage: _.toNumber(page),
    paginationMetadata,
    onChangePage: (page) => {
      setPage(page);
    },
  });

  return <PaginationBar pagination={pagination} />;
};

const TripsContent = ({paginationMetadata, page, setPage, trips, form, shipmentIds}: any) => {
  return (
    <ScrollView>
      <TripsContainer>
        {trips.map((trip: any) => {
          return (
            <TripBlock key={trip.id} isChecked trip={trip} form={form} shipmentIds={shipmentIds} />
          );
        })}
      </TripsContainer>
      <PaginationFooter paginationMetadata={paginationMetadata} page={page} setPage={setPage} />
    </ScrollView>
  );
};

const AssignShipmentsToTripSidebarContent = ({
  assignShipmentsToTripSidebar,
  shipmentIds,
  onSuccess,
}: any) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeQuery = useDebouncedCallback((query) => setSearchQuery(query), 250);
  const {data} = useQuery(AssignShipmentsToTripSidebar.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      statuses: [TripStatus.PENDING, TripStatus.PLANNED, TripStatus.IN_TRANSIT],
      pagination: {
        page,
        resultsPerPage: 10,
      },
      searchQuery,
    },
  });
  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});
  const assignShipmentsToTripForm = AssignShipmentsToTripForm.new({tripId: null, shipmentIds});
  const {form, submitting, handleSubmit} = useAssignShipmentsToTripMutation({
    assignShipmentsToTripForm,
    onSuccess,
    onError: (errors: any) => {
      console.log(errors);
      alertHandler.handleMutationAlertErrors({errors});
    },
  });
  return (
    <Sidebar.Content
      isOpen={assignShipmentsToTripSidebar.isOpen}
      position={'right'}
      onClose={() => assignShipmentsToTripSidebar.handleClose()}
    >
      <SidebarContainer>
        <HeaderContainer>
          <HeaderText>Assign Shipments To Trip</HeaderText>
        </HeaderContainer>
        <Line />
        <Space height={12} />
        <Container style={{paddingHorizontal: '12px'}}>
          <SearchBar
            placeholder={'Search by trip details'}
            style={{flex: 1}}
            onChangeText={(text) => handleChangeQuery(text)}
          />
        </Container>
        <Space height={12} />
        <Loading loading={!data} as={PageLoadingIndicator}>
          {() => (
            <TripsContent
              paginationMetadata={data.paginatedList.paginationMetadata}
              page={page}
              setPage={setPage}
              trips={data.paginatedList.trips}
              form={form}
              shipmentIds={shipmentIds}
            />
          )}
        </Loading>
        <Row style={{justifyContent: 'flex-end', paddingHorizontal: 16, paddingVertical: 12}}>
          <QuaternaryButton
            onPress={assignShipmentsToTripSidebar.handleClose}
            text={'Cancel'}
            textColor={colors.gray.secondary}
          />
          <Space width={16} />
          <Button
            onPress={handleSubmit}
            isSubmitting={submitting}
            isDisabled={!form.values.assignShipmentsToTripForm.tripId}
            text={'Assign'}
          />
        </Row>
      </SidebarContainer>
      <ToastContainer />
    </Sidebar.Content>
  );
};
const AssignShipmentsToTripSidebar = ({
  assignShipmentsToTripSidebar,
  shipmentIds,
  onSuccess,
}: any) => {
  return (
    <Loading loading={false} as={PageLoadingIndicator}>
      {() => {
        return (
          <AssignShipmentsToTripSidebarContent
            assignShipmentsToTripSidebar={assignShipmentsToTripSidebar}
            shipmentIds={shipmentIds}
            onSuccess={onSuccess}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignShipmentsToTripSidebar.query = gql`
  ${usePagination.fragment}
  ${Location.getDisplayCityStateZip.fragment}

  query AssignShipmentsToTripSidebar(
    $statuses: [String]!
    $pagination: PaginationInput!
    $searchQuery: String
  ) {
    paginatedList: filteredTripsPaginatedList(
      statuses: $statuses
      pagination: $pagination
      searchQuery: $searchQuery
    ) {
      trips: results {
        id
        name
        startDate
        endDate
        totalWeight
        driver {
          id
          fullName
        }
        startLocation {
          id
          ...Location_getDisplayCityStateZip
        }
        endLocation {
          id
          ...Location_getDisplayCityStateZip
        }
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

export default AssignShipmentsToTripSidebar;
