// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useHandleMutationAlertErrors} from '@supermove/hooks';

// App
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import TagForm from '@shared/modules/Tag/forms/TagForm';
import useArchiveTagMutation from '@shared/modules/Tag/hooks/useArchiveTagMutation';

const ArchiveTagModal = ({tag, isOpen, handleClose, onSuccess}: any) => {
  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});
  const tagForm = TagForm.edit(tag);
  const {handleSubmit, submitting} = useArchiveTagMutation({
    tagForm,
    onSuccess: () => {
      handleClose();
      onSuccess && onSuccess();
    },
    onError: (errors: any) => {
      handleClose();
      console.log({errors});
      alertHandler.handleMutationAlertErrors({errors});
    },
  });

  return (
    <WarningModal
      isOpen={isOpen}
      title={`Archive ${tag.name}?`}
      message={'Archiving this tag might impact workflows.'}
      handleSecondaryAction={handleClose}
      handlePrimaryAction={handleSubmit}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ArchiveTagModal.fragment = gql`
  fragment ArchiveTagModal on Tag {
    id
    ...TagForm_edit
  }
`;

export default ArchiveTagModal;
