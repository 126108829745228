// Libraries
import React from 'react';

// App
import {Loading, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useState} from '@supermove/hooks';
import {UserFlowModel, ProjectModel, UserModel} from '@supermove/models';

// Components
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import UserFlowRunForm from '@shared/modules/UserFlows/forms/UserFlowRunForm';
import useCreateAndSendUserFlowRunMutation from '@shared/modules/UserFlows/hooks/useCreateAndSendUserFlowRunMutation';
import CreateDocumentFlowRunPageContent from 'modules/Project/DocumentFlow/components/CreateDocumentFlowRunPageContent';
import CreateDocumentFlowRunPageHeader from 'modules/Project/DocumentFlow/components/CreateDocumentFlowRunPageHeader';

const Container = Styled.View`
  flex: 1;
`;

const CreateDocumentFlowRunPageBody = ({
  viewer,
  project,
  userFlow,
}: {
  viewer: UserModel;
  project: ProjectModel;
  userFlow: UserFlowModel;
}) => {
  const {navigator} = useNavigationDOM();
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);

  const userFlowRunForm = UserFlowRunForm.new({
    viewer,
    project,
    userFlow,
  });
  const {form, submitting, handleSubmit} = useCreateAndSendUserFlowRunMutation({
    userFlowRunForm,
    onSuccess: navigator.goBack,
    onError: (errors: unknown) => {
      console.log({errors});
    },
  });

  return (
    <Container>
      <CreateDocumentFlowRunPageHeader
        handleSend={handleSubmit}
        userFlow={userFlow}
        isLoading={isGeneratingPreview || submitting}
      />
      <CreateDocumentFlowRunPageContent
        viewer={viewer}
        project={project}
        userFlow={userFlow}
        form={form}
        setIsGeneratingPreview={setIsGeneratingPreview}
      />
    </Container>
  );
};

const CreateDocumentFlowRunPage = () => {
  const {
    params: {projectUuid, userFlowUuid},
  } = useNavigationDOM();

  const {data, loading} = useQuery<{
    viewer: UserModel;
    project: ProjectModel;
    userFlow: UserFlowModel;
  }>(CreateDocumentFlowRunPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      projectUuid,
      userFlowUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (
        <CreateDocumentFlowRunPageBody
          viewer={data!.viewer}
          project={data!.project}
          userFlow={data!.userFlow}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateDocumentFlowRunPage.query = gql`
  ${CreateDocumentFlowRunPageHeader.fragment}
  ${CreateDocumentFlowRunPageContent.fragment}
  ${UserFlowRunForm.new.fragment}
  query CreateDocumentFlowRunPage($projectUuid: String!, $userFlowUuid: String!) {
    ${gql.query}
    viewer {
      id
      ...UserFlowRunForm_new_User
      ...CreateDocumentFlowRunPageContentFragment_User
    }
    project(uuid: $projectUuid) {
      id
      uuid
      ...CreateDocumentFlowRunPageContentFragment_Project
      ...UserFlowRunForm_new_Project
    }
    userFlow(uuid: $userFlowUuid) {
      id
      uuid
      ...CreateDocumentFlowRunPageContentFragment_UserFlow
      ...CreateDocumentFlowRunPageHeaderFragment
      ...UserFlowRunForm_new_UserFlow
    }
  }
`;

export default CreateDocumentFlowRunPage;
